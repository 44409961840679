import { Button, Card, Col, Form, Input, Row, Typography as T } from 'antd'
import { navigate } from 'gatsby'
import React, { useState } from 'react'
import Layout from '../components/Layout'
import MenuCategory from '../components/MenuCategory'
import SEO from '../components/SEO'
import i18n from '../templates/i18n'


const ContactPage = ({ location, pageContext: { locale } }: any) => {
  const { TextArea } = Input
  const lang = i18n[locale]
  const [processing, setProcessing] = useState(false)

  const onFinish = (values: any) => {
    setProcessing(true)
    const db = window.firebase.database()
    const key = db.ref().child('contacts').push().key;

    db.ref(`/contacts/${key}`).set(values).then(() => {
      setProcessing(false)
      navigate('/success')
    });
  }

  return (
    <Layout locale={locale} location={location}>
      <SEO location={location} locale={locale} title={lang.contact} />
      <MenuCategory type="shop" locale={locale} title={lang.contact} />
      <T.Paragraph>
        <T.Title level={4} type="danger">
          {lang.hotline}
        </T.Title>
      </T.Paragraph>
      <Row gutter={[20, 16]}>
        <Col xs={24} sm={16}>
          <Form
            name="contact"
            layout="vertical"
            method="post"
            onFinish={onFinish}
          >
            <Form.Item name="name" label={lang.contactName} rules={[{ required: true, message: lang.contactErrors.emptyName }]}>
              <Input />
            </Form.Item>
            <Form.Item name="email" label="Email" rules={[
              {
                type: 'email',
                message: lang.contactErrors.invalidEmail,
              },
            ]}>
              <Input type="email" />
            </Form.Item>
            <Form.Item name="phone" label={lang.phone}>
              <Input />
            </Form.Item>
            <Form.Item name="message" label={lang.contactMessage} rules={[{ required: true, message: lang.contactErrors.emptyMessage }]}>
              <TextArea placeholder={lang.messgeDesc} minLength={1} maxLength={7024} />
            </Form.Item>
            <Form.Item>
              <Button loading={processing} type="primary" htmlType="submit">
                {lang.sendMessage}
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} sm={8}>
          <Card style={{ marginBottom: '1rem' }} hoverable={true} title={lang.headQuarter}>
            <T.Text>{lang.hQAddress}</T.Text>
          </Card>
          <Card hoverable={true} title={lang.nursery}>
            {lang.nurseryAddress}
          </Card>
        </Col>
      </Row>
    </Layout>
  )
}

export default ContactPage
